import { useSignedIn } from 'modules/react-auth';

/**
 *
 * Content is only visible if user is anonymous
 *
 * @param {*} param0
 */
export const UserAnonBorder = ({ children }) => {
  const {signedIn} = useSignedIn();

  return <>{signedIn === false && <>{children}</>}</>;
};
