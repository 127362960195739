import { css } from "@emotion/react";
import { Box, Divider, lighten, Typography } from "@mui/material";
import Link from "next/link";
import { FC, forwardRef, ReactNode } from "react";
import { getCategoriesMap } from "utils/arrayUtils";
import { ElementLink } from "./LinkNormal";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IconRenderer } from "../specials/icons/IconRenderer";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const styleDef = {
    text: theme=>css({
        color: theme.palette.text.primary,
        fontSize: '1.1em',
        fontWeight: '500',
        whiteSpace: 'nowrap'
    }),
    textSecondary: css({
        fontWeight: '500',
        fontSize: '0.95em',
    }),
    //---
    subLinkOnSmallScreen: theme=>({
        paddingLeft: theme.spacing(3),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    }),
    categoryTitleContInDrawer:  theme=>({
        color: theme.palette.text.secondary,
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        //textTransform: 'uppercase',
    }),
    //---
    divider: theme=>({
        marginTop: theme.spacing(1)
    })
}

export interface LinkAppBarProps {
    startText?: string
    href?: string
    children?: ReactNode 
    target?: string
    variant?: string
    prefetch?: boolean
    notClickable?: boolean
    expandIcon?: boolean
    expanded?: boolean
    disableClientSideNav?: boolean
    fullWidth?: boolean
    linkStyle?: any
}

export const LinkAppBar: FC<LinkAppBarProps> = forwardRef<HTMLDivElement, LinkAppBarProps>(({startText, href, children, target, variant, prefetch, notClickable, expandIcon, expanded, disableClientSideNav, fullWidth, linkStyle}, ref) => {

    const allTextStyles = [styleDef.text] as any[]
    if (variant === "secondary") {
        allTextStyles.push(styleDef.textSecondary)
    }

    const styles = {
        link: theme=>({
            color: theme.palette.text.primary,
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            width: fullWidth ? '100%' : null,
            '&:hover': {
                //textDecoration: 'underline',
                cursor: 'pointer',
                color: lighten(theme.palette.primary.main,0.3),
            },
            '&:hover $text': {
                color: lighten(theme.palette.primary.main,0.3),
            },
            ...linkStyle,
        }),
        startText: theme=>({
            color: theme.palette.text.secondary,
            fontSize: '14px',
        }),
    }

    const renderExpandIconIfVisible = () => {
        return (expandIcon && 
            <KeyboardArrowDownIcon 
                className="linkAppBar-icon"
                sx={{
                    marginLeft: '1px',
                    fontSize: '1.3em',
                    transform: expandIcon && expanded ? 'rotate(180deg)' : null,
                    transition: '300ms ease all',
                }}
            />
        )
    }

    return (
        <>
            {startText && (
                <><Typography component="span" sx={styles.startText}>{startText}</Typography>&nbsp;</>
            )}
            <div 
                ref={ref}
                css={{
                    display: 'inline-block',
                    width: fullWidth ? '100%' : null,
                }}
                >
                {notClickable ? 
                    <Typography component="div" 
                        sx={[...allTextStyles, {
                            cursor: 'default',
                            display: 'flex',
                            alignItems: 'center',
                        }]}
                    >
                        {children}
                        {renderExpandIconIfVisible()}
                    </Typography>
                    :
                    (disableClientSideNav 
                        ? <a href={href} css={styles.link} target={target}>
                            <Typography component="span" sx={allTextStyles}>{children}</Typography>
                            {renderExpandIconIfVisible()}
                        </a>
                        :<Link href={href} style={{display: 'inline-block'}} prefetch={prefetch}>
                            <a href={href} css={styles.link} target={target}>
                                <Typography component="span" sx={allTextStyles}>{children}</Typography>
                                {renderExpandIconIfVisible()}
                            </a>
                        </Link>
                    )
                }

            </div>
        </>
    );

})

const submenuStyleDef = {
    linkSx: (theme)=>({
        display: 'block',
        borderRadius: '8px',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        width: '335px',
        '& .arrow-icon': {
            opacity: '0',
            width: '2px',
            height: '15px',
            transition: 'all 0.2s linear 0s',
            color: theme.palette.text.primary,
            marginLeft: '4px',
        },
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.palette.action.hover,
            '& .arrow-icon': {
                opacity: '1',
                width: '15px',
            },
        },
        '& .description': {
            marginTop: 0,
            color: theme.palette.text.secondary,
            minHeight: '2.5em',
            fontSize: '0.92em',
        },
    }),
    categoryTitle: (theme)=>({
        fontSize: '12px',
        fontWeight: '600',
        color: theme.palette.text.secondary,
        paddingTop: theme.spacing(1.5),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textTransform: 'uppercase',
    }),
    subMenuContentTitle: theme=>({
        color: theme.palette.text.primary,
        fontSize: '1.1em',
        marginBottom: theme.spacing(0.5),
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center'
    }),
}

export const AppBarSubMenuContent = ({items}) => {

    const categories = items ? getCategoriesMap(items) : null;

    return (
        <Box pt={1} pl={1.5} pr={1.5} pb={1.5}>
            {categories && Object.keys(categories).map((key, idx)=>{
                const items = categories[key]

                return (
                    <Box key={idx}>
                        <Typography sx={submenuStyleDef.categoryTitle} component="div">{key}</Typography>
                        <Box 
                            mt={1} 
                            pb={2}
/*                             display="flex" 
                            flexDirection="row" 
                            flexWrap="wrap"  */
                            maxHeight="400px" 
                            sx={{
                                display: 'grid',
                                gridAutoFlow: 'column',
                                gridTemplateRows: 'repeat(3, auto)',
                                gridGap: '8px 24px',
                            }}
                            >
                            {items.map((i,idx)=>
                                <ElementLink href={i.href} key={idx} sx={submenuStyleDef.linkSx} disableClientSideNav>
                                    <Box display="flex">
                                        {i.icon && <div css={{paddingRight: '12px', paddingTop: '1px',}}><IconRenderer name={i.icon} /></div>}
                                        <div>
                                            <Typography sx={submenuStyleDef.subMenuContentTitle}>{i.title}<ArrowForwardIcon className="arrow-icon"/></Typography>
                                            <Typography className="description" component="div">{i.description}</Typography>
                                        </div>
                                    </Box>
                                </ElementLink>
                            )}
                        </Box>
                    </Box>
                )
            })
        }
        </Box>
    )

}

export const AppBarSubMenuContentDrawer = ({items}) => {

    const categories = items ? getCategoriesMap(items) : null;

    const renderCategories = (categories) => {
        
        const hideCategoryTitle = categories && Object.keys(categories).length === 1

        return Object.keys(categories).map((key, idx)=>{

            const items = categories[key]

            const subLinks = items.map((i,idx)=>{
                return <div css={styleDef.subLinkOnSmallScreen} key={idx}><LinkAppBar href={i.href} disableClientSideNav>{i.title}</LinkAppBar></div>
            })

            return (
                <Box key={idx}>
                    {hideCategoryTitle !== true &&
                        <div css={styleDef.categoryTitleContInDrawer}>
                            <Typography>{key}</Typography>
                            <Divider sx={styleDef.divider}/>
                        </div>
                    }
                    <Box mt={1}>
                        {subLinks}
                    </Box>
                </Box>
            )
        })
    }

    return (
        <>
            {renderCategories(categories)}
        </>
    )

}