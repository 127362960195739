import { LinkAppBar } from "modules/picasso-ui/link/LinkAppBar"
import { useTranslation } from "next-i18next"
import { appRoutes } from "utils/appRoutes"
import { ElementLink } from "modules/picasso-ui/link/LinkNormal"
import { Button } from "@mui/material"

export const LoginAppBarLink = ({component, fullWidth, linkStyle, ...props}) => {

    const { t } = useTranslation('yoio')

    if (component === 'button') {
        // prefetch: false because otherwise modules from login page will be loaded!
        return (
            <ElementLink href={appRoutes.yoio.access.login.buildPath()} fullWidth={fullWidth} sx={linkStyle} prefetch={false}>
                <Button color="primary" variant="contained" size="medium" fullWidth={fullWidth} disableClientSideNav {...props}>
                    {t('yoio:access.login.loginText')}
                </Button>
            </ElementLink>
        )
    }

    return (
        <LinkAppBar href={appRoutes.yoio.access.login.buildPath()} prefetch={false} disableClientSideNav {...props}>
            {t('yoio:access.login.loginText')}
        </LinkAppBar>
    )

}

export const PricingPageLink = (props) => {

    const { t } = useTranslation('yoio')

    return (
        <LinkAppBar href={appRoutes.yoio.landingPage.pricingPage.buildPath()} disableClientSideNav {...props}>
            {t('yoio:landingPage.pricingPage.title')}
        </LinkAppBar>
    )

}

export const SignupAppBarLink = () => {

    const { t } = useTranslation('yoio')

    return <GetStartedAppBarLink text={t('yoio:access.signup.title')}/>

}

export const GetStartedAppBarLink = ({buttonId, buttonClass, text, fullWidth, linkStyle, href, ...props}) => {

    const { t } = useTranslation('yoio')

    // prefetch: false because otherwise modules from signup page will be loaded!
    return (
        <ElementLink href={href??appRoutes.yoio.access.getStarted.buildPath()} fullWidth={fullWidth} sx={linkStyle} prefetch={false}>
            <Button color="primary" variant="contained" size="medium" id={buttonId} className={buttonClass} fullWidth={fullWidth} {...props} sx={[{borderWidth: '2px !important'},props.sx]}>
                {text || t('access.signup.getStartedText')}
            </Button>
        </ElementLink>
    )
        
}