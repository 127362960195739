export const AppBarPush = ({xs, sm, md}:{xs?:string|number, sm?:string|number, md?:string|number}) => {

    xs = xs || '70px'
    sm = sm || '70px'
    md = md || '70px'

    return (
        <>
            <div style={{height:xs}} 
                css={(theme)=>({
                    width: '100%',
                    display: 'none',
                    [theme.breakpoints.only('xs')]: {
                        display: 'block'
                    }
                })}
            ></div>
            <div style={{height:sm}} 
                css={(theme)=>({
                    width: '100%',
                    display: 'none',
                    [theme.breakpoints.only('sm')]: {
                        display: 'block'
                    }
                })}
            ></div>
            <div style={{height:md}}
                css={(theme)=>({
                    width: '100%',
                    display: 'none',
                    [theme.breakpoints.up('md')]: {
                        display: 'block'
                    }
                })}
            ></div>
        </>
    )
}