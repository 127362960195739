import { AppBar, Container, Toolbar, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HideOnScroll } from 'modules/picasso-ui/main/ShowOnScroll';
import { BetaChip } from 'modules/picasso-ui/present/BetaChip';
import { forwardRef } from 'react';
import { isHavingValue } from 'utils/objectUtils';
import { AppLogoSmall } from './AppLogoSmall';

const AppBarLandingSaas = forwardRef((props, ref) => {
  const classes = useStyles();
  
  const { backgroundColor, scrollBackgroundColor, children, zIndex, contentLeft, contentCenter, responsive, maxWidth, logoProps, hideAppLogo, hideOnScrollDown, position, slim, betaLabelVisible, logoEndContent } = props

  const toolbarClassNames = [classes.toolbar]

  if (responsive === true) {
    toolbarClassNames.push(classes['toolbar-responsive'])
  }

  const maxWidthEffective = maxWidth || 'lg'
  
  const appBarCss = [(theme)=>({
      boxShadow: 'none',
      backgroundColor: backgroundColor||'#fff',
      height: '70px',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
      zIndex: isHavingValue(zIndex) ? zIndex : null,
      '&.effectAnyOnScroll': {
        borderBottom: `1px solid transparent`,
      },
      '&.effectAnyOnScroll-active': {
        backgroundColor: scrollBackgroundColor||'rgba(255, 255, 255, 0.75)',
        borderBottom: `1px solid ${theme.palette.divider}`,
        // backdropFilter must be applied on '::before'. If applied to parant, child popovers will not work because they use position:absolute.
        '&::before': {
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            position: 'absolute',
            backdropFilter: 'blur(5px)',
        },
      },
  })]

  if (slim) {
    appBarCss.push(()=>({
      paddingTop: '2px',
      height: '50px',
      '& $toolbar': {
        height: '50px',
        minHeight: '0px',
      }
    }))
  }

  let className = props.className || ''
  className = className + ' effectAnyOnScroll'
  
  const renderAppBarCore = () => {
    return (  
      <AppBar position={position || "fixed"} color="primary" classes={(className? {root: className}:null)} ref={ref} sx={appBarCss}>
        <Container maxWidth={maxWidthEffective} style={{ margin: 'auto' }}>
          <Toolbar disableGutters={true} className={toolbarClassNames.join(' ')} sx={{'&>*':{flex: 1}}}>
              <Box display="flex" alignItems="center">
                  {hideAppLogo !== true && (
                    <><AppLogoSmall openInNewTab={false} {...logoProps} />{logoEndContent}</>
                  )}
                  {betaLabelVisible && 
                    <Box ml={'10px'} display="flex" alignItems="center" mt={'1px'}><BetaChip /></Box>
                  }
                  {contentLeft && (
                    <>{contentLeft}</>
                  )}
              </Box>
              {contentCenter && (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <>{contentCenter}</>
                </Box>
              )}
              <Box display="flex" alignItems="center" flex={children ? 1 : 0} justifyContent="flex-end">
                {children}
              </Box>
          </Toolbar>
        </Container>
      </AppBar>
      )
  }

  return (
      <>
        {hideOnScrollDown === true ? 
          <HideOnScroll scrollClass={classes.appBarWhenScrolled} threshold={200}>
            {renderAppBarCore()}
          </HideOnScroll>
          : renderAppBarCore()
        }
      </>
  );
})

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
  },
  appBarWhenScrolled: {
/*     backgroundColor: 'rgba(255, 255, 255, 0.75)',
    backdropFilter: 'blur(5px)',
    borderBottom: `1px solid ${theme.palette.divider}`, */
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      '&>*': {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }
    }
  },
  'toolbar-responsive': {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

AppBarLandingSaas.displayName = 'AppBarLandingSaas'
export { AppBarLandingSaas as AppBarLandingSaas }