import { Box, BoxProps } from "@mui/material"
import { FunctionComponent } from "react"
import { orElse } from "utils/objectUtils"

export interface ButtonBarProps {
    spacing?: string | number
    align?: string
    style?: any
    directionColumnBreakpoint?: string
    fullWidth?: boolean
    /**
     * If true, is always column
     */
    column?: boolean
}

export const ButtonBar: FunctionComponent<ButtonBarProps & BoxProps> = ({spacing, align, style, sx, directionColumnBreakpoint, fullWidth, column, ...props}) => {

    spacing = orElse(spacing, 1)

    const styles: any = {
        root: theme=>({
            display: 'flex',
            alignItems: 'center',
            gap: typeof spacing === 'number' ? theme.spacing(spacing) : spacing,
            justifyContent: (align === 'right'? 'flex-end':null),
            '& > *': {
                flex: fullWidth ? 1 : null
            },
            flexDirection: column ? 'column':null,
        }),
    }

    let rootStyle = [styles.root]

    if (directionColumnBreakpoint) {
        rootStyle.push(theme=>({
            [theme.breakpoints.down(directionColumnBreakpoint)]: {
                flexDirection: 'column',
                alignItems: fullWidth ? 'stretch':'flex-start',
            }
        }))
    }
    if (sx) {
        rootStyle.push(sx)
    }
    if (style) {
        rootStyle.push(style)
    }

    return <Box sx={rootStyle} {...props} />
}