import { ChipStyled } from "./chip/ChipStyled"

export const BetaChip = ({sx}) => {

    const styles = [
        {
            border: '1px solid rgb(20 32 58 / 36%)',
            background: 'none',
            color: 'rgb(20 32 58 / 46%)',
            borderRadius: '6px',
            height: '17px',
            '&>*': {
                paddingLeft: '4px',
                paddingRight: '4px',
            }
        }
    ]

    if (sx) {
        styles.push(sx)
    }

    return (
        <ChipStyled label="BETA" sx={styles} />
    )

}