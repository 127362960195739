import { WebsitePageScaffold } from 'modules/yoio/scaffold/WebsitePageScaffold';
import { Box, Container } from '@mui/material';
import { useLandingPage } from 'modules/landingPage/LandingPageContext';
import { AppBarPush } from 'components/layout/positioning/AppBarPush';
import { LandingPageScreenAppBar } from './LandingPageScreenAppBar';
import { useLandingPageFooter } from 'modules/landingPage/utils/useLandingPageFooter';
import { renderContent } from 'modules/landingPage/LandingPageModuleRenderer';
import { ReactNode } from 'react';

export const LandingPageScreenScaffold = ({children, appBarProps}:{children: ReactNode, appBarProps?: any}) => {

    const { landingPage } = useLandingPage()
    
    return (
        <WebsitePageScaffold>
            {landingPage && (
                <>
                    {landingPage.appBar && <LandingPageScreenAppBar landingPage={landingPage} {...appBarProps}/>}
                    {landingPage.appBar && (appBarProps?.position !== 'relative' && landingPage.appBar?.position !== 'relative' && landingPage.appBar?.appBarPushEnabled !== false) && <AppBarPush />}
                    
                    {children}
                </>
            )}
        </WebsitePageScaffold>
    );
}

export interface LandingPageScreenScaffoldFooterProps {
    landingPage?: any
    simple?: boolean
}

export const LandingPageScreenScaffoldFooter = ({landingPage, simple}:LandingPageScreenScaffoldFooterProps) => {

/*     const start = Date.now(); */

    const { populatePageFooter } = useLandingPageFooter()

    const content = []

    const footer = landingPage?.content?.find(c=>c.type==='LandingFooterSaas' || c.type==='FooterSimple')
    
    if (footer) {
        if (simple) {//replace by simple footer
            content.push({
                type: 'FooterSimple',
                props: {
                    showDivider: footer.props?.showDivider,
                    showLogo: footer.props?.showLogo,
                    component: 'div', //because current component already wraps in footer
                },
            })
        } else {
            content.push({...footer})
        }
    }
    
    const page =  landingPage? {
        content,
        privacyPolicy: landingPage.privacyPolicy,
        legalNotice: landingPage.legalNotice,
        termsEnabled: landingPage.termsEnabled,
    } : null

    const contentEffective = populatePageFooter(page)

/*     const end = Date.now();
    console.log(`Execution time (LandingPageScreenScaffoldFooter) : ${end - start} ms`); */

    return (
        <Box pt={4} component="footer">  
            {page && (
                <Container maxWidth={footer?.maxWidth || 'lg'}>
                    {renderContent(contentEffective, false)}
                </Container>
            )}
        </Box>
    )

}