import { AppBarLandingSaas } from 'modules/yoio/component/AppBarLandingSaas';
import { Box, popoverClasses, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { appRoutes } from 'utils/appRoutes';
import ToolbarResponsive from 'modules/picasso-ui/menu/ToolbarResponsive';
import { AppBarSubMenuContent, AppBarSubMenuContentDrawer, LinkAppBar } from 'modules/picasso-ui/link/LinkAppBar';
import { GetStartedAppBarLink, LoginAppBarLink, PricingPageLink } from '../../plinzip/screen/landing/GetStartetAppBarLink';
import { notNullNotUndefined } from 'utils/objectUtils';
import { InteractionPopover } from 'modules/picasso-ui/present/list/InteractionPopover';
import { ButtonBar } from 'modules/picasso-ui/interact/ButtonBar';
import { LandingFormDialogButton } from 'modules/landingPage/LandingFormDialogButton';

export const LandingPageScreenAppBar = ({landingPage, hideLogin, hideGetStarted, hideLinks, position, logoEndContent, disableResponsiveMenu}) => {

    const { t } = useTranslation(['yoio'])

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const smallScreenLinkStyle = { paddingTop: '10px', paddingBottom: '10px', '&>*': { fontSize: '16px !important'} }

   const renderSubMenuLinkBigScreen = ({title, href, items})=>{
        if (!items || items.length === 0) {
            return (
                <LinkAppBar key={title} href={href||'#'} notClickable={!href} disableClientSideNav>
                    {title}
                </LinkAppBar>
            )
        }

       return <InteractionPopover 
           key={title} 
           content={<AppBarSubMenuContent items={items}/>}
           anchorOrigin={{
               horizontal: 'center',
               vertical: 'bottom'
           }}
           transformOrigin={{
               vertical: 'top',
               horizontal: 'center',
           }}
           sx={{
               marginTop: '16px',
               [`.${popoverClasses.paper}`]: {
                   borderRadius: '1em',
                   boxShadow: '0 50px 100px -20px rgba(50,50,93,0.25),0 30px 60px -30px rgba(0,0,0,0.3)',
               }
           }}
           // keepMounted and disablePortal: very important for SSR to have LINKS in HTML
           keepMounted={true}
           disablePortal={true}
           >
           {(props, open)=>{
            return <Box {...props} display="inline-block" sx={{lineHeight: '19px'}}>
                <LinkAppBar href={href||'#'} notClickable={!href} expanded={open} disableClientSideNav>
                    {title}
                </LinkAppBar>
            </Box>}
            }
       </InteractionPopover>
   }

    const renderSubMenuLinkSmallScreen = ({title, href, items})=>{

        if ((!items || items.length === 0) && href) {
            return <LinkAppBar key={title} href={href||'#'} disableClientSideNav fullWidth linkStyle={smallScreen ? smallScreenLinkStyle : null}>{title}</LinkAppBar>
        }

        return <div key={title}> 
            {href 
                ? <LinkAppBar href={href||'#'} disableClientSideNav fullWidth linkStyle={smallScreen ? smallScreenLinkStyle : null}>{title}</LinkAppBar>
                :  <Typography component="div">{title}</Typography>
            }
           
            {items?.length > 0 &&
                <div>
                    <AppBarSubMenuContentDrawer items={items}/>
                </div>
            }
        </div>
    }

    const renderSubMenus = (subMenus) => {
        return smallScreen ? subMenus.map(renderSubMenuLinkSmallScreen) : subMenus.map(renderSubMenuLinkBigScreen)
    }

    const renderSubPageLink = ({pageKey}) => {
        notNullNotUndefined(pageKey)

           return <LinkAppBar href={appRoutes.yoio.landingPage[pageKey].buildPath()}>
               {t(`yoio:landingPage.${pageKey}.title`)}
           </LinkAppBar>
    }

    const renderCustomLink = ({text, href, target}) => {
          return <LinkAppBar href={href} target={target} fullWidth={smallScreen===true} linkStyle={smallScreen ? smallScreenLinkStyle : null}>{text}</LinkAppBar>
   }

    const getToolbarItemsRight = () => {
       const items = []

       if (landingPage.appBar) {
           if (landingPage.appBar.loginLinkVisible && hideLogin !== true) {
               const smallScreenProps = smallScreen ? {
                   component: 'button',
                   size: 'large',
                   variant: 'outlined',
               } : null
               items.push(<LoginAppBarLink {...smallScreenProps} fullWidth={smallScreen===true} linkStyle={smallScreen ? smallScreenLinkStyle : null} />)
           }
           if (landingPage.appBar.getstartedLinkVisible && hideGetStarted !== true) {
                let getstartedElem = null
                const btnProps = { size: smallScreen ? 'large':'medium', hoverInvert: smallScreen, variant: smallScreen?'contained':'outlined', fullWidth: smallScreen===true  }
                if (landingPage.appBar.getstartedFormDialog) {
                    getstartedElem = <LandingFormDialogButton toggleButtonProps={btnProps} {...landingPage.appBar.getstartedFormDialog} />
                } else {
                    getstartedElem = <GetStartedAppBarLink {...btnProps} linkStyle={smallScreen ? smallScreenLinkStyle : null} href={landingPage.appBar.getstartedLinkHref} />
                }
                if (smallScreen) {
                    // Insert before login
                    items.splice(Math.max(items.length-2, 0), 0, getstartedElem)
                } else {
                    items.push(getstartedElem)
                }
           } 
       }
/*         if (landingPage.languagesSelectable) {
           items.push(<Box display="inline-block"><LanguageSwitch 
               languages={landingPage.languagesSelectable}
               MenuProps={{ disablePortal: true }} //because in drawer on small screens, doesnt work,
           /></Box>)
       } */
       return items
   }

    const getAppBarMiddleLinks = () => {
        const items = []

        if (landingPage.appBar?.pageLinks) {
            landingPage.appBar.pageLinks.forEach((l)=>{
                if (l.type === 'link') (
                    items.push(renderCustomLink(l))
                )
                else if (landingPage[l]) {
                    if (l.type === 'subpage') {
                        items.push(renderSubPageLink(l))
                    } 
                    else if (!l.type) {//old
                        items.push(renderSubPageLink({ 
                            pageKey: l
                        }))
                    } 
                }
            })
        }
        if (landingPage.appBar?.subMenus) {
            const subMenus = landingPage.appBar.subMenus
                .filter(s=>s.title !== 'Blog')
                .map(s=>{
                    if (s.items) {
                        return { ...s, items: s.items.filter(i=>i.visible != false) }
                    } else {
                        return s
                    }
            });
            const subMenusElems = renderSubMenus(subMenus)
            subMenusElems.forEach((i)=>{
                items.push(i)
            })
        }
        if (landingPage.appBar?.pricingPageLinkVisible) {
            items.push(<PricingPageLink key="pricing" fullWidth={smallScreen===true} linkStyle={smallScreen ? smallScreenLinkStyle : null} />)
        }

        //append some to the end, e.g. blog, so that they are after pricing
        if (landingPage.appBar?.subMenus) {
            const subMenus = renderSubMenus(landingPage.appBar.subMenus.filter(s=>s.title === 'Blog'))
            subMenus.forEach((i)=>{
                items.push(i)
            })
        }
        return items
    }

    let contentLeft = hideLinks !== true ? 
        <div css={{
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                alignItems: 'center',
                marginLeft: theme.spacing(10),
            }
        }}>
            <ButtonBar spacing={6}>{getAppBarMiddleLinks()}</ButtonBar>
        </div> 
    : null

    return (
        <>
            <AppBarLandingSaas
                //hideOnScrollDown={true}
                logoEndContent={logoEndContent}
                contentLeft={contentLeft}
                logoProps={{
                    iconOnly: false,
                }}
                betaLabelVisible={landingPage.appBar?.betaLabelVisible}
                position={position || landingPage.appBar?.position}
                backgroundColor={landingPage.appBar?.backgroundColor}
                scrollBackgroundColor={landingPage.appBar?.scrollBackgroundColor}
                maxWidth={landingPage.appBar?.maxWidth}
            >
                <ToolbarResponsive 
                    //itemsCenter={getAppBarMiddleLinks()}
                    itemsOnResponsiveOnly={hideLinks !== true ? getAppBarMiddleLinks() : null}
                    itemsRight={getToolbarItemsRight()}
                    disableResponsiveMenu={disableResponsiveMenu}
                />
            </AppBarLandingSaas>
            <script
                defer={true}
                src="/lp/lp.js"
/*                 key="lps-appbar-getstartedbtn-scroll"
                dangerouslySetInnerHTML={{"__html": ` 
                    console.log('test')
                `}}  */
            />

        </>
    )

}