import { useTranslation } from "next-i18next"
import { notNullNotUndefined } from "utils/objectUtils"

export const useLandingPageFooter = () => {

    const { t, i18n } = useTranslation('yoio')

    const createAutoFooter = () => {
        return {
            type: 'LandingFooterSaas',
        }
    }

    const urlLanguage = i18n.language!=='en'?i18n.language:null

    const addLegalLinksToFooter = (footer, page) => {

        const {legalNotice, privacyPolicy, termsEnabled} = page

        footer.props = footer.props || {}
        footer.props.linksLegal = footer.props.linksLegal || []

        if (privacyPolicy) {
            if (!footer.props.linksLegal.find(l=>l.href==='/privacy-policy')) {
                footer.props.linksLegal.push({
                    text: t('legal.privacyPolicy.title'),
                    href: '/privacy-policy',
                    category: t('landingPage.footer.categories.legal.title'),
                    type: 'legal',
                    rel: 'nofollow',
                })
            }
        }

        if (legalNotice) {
            const searchUrl = urlLanguage ? `/${urlLanguage}/legal-notice`: '/legal-notice'

            if (!footer.props.linksLegal.find(l=>l.href===searchUrl)) {
                footer.props.linksLegal.push({
                    text: t('legal.legalNotice.title'),
                    href: searchUrl,
                    category: t('landingPage.footer.categories.legal.title'),
                    type: 'legal',
                    rel: 'nofollow',
                })
            }
        }

        if (termsEnabled === true) {
            if (!footer.props.linksLegal.find(l=>l.href==='/terms')) {
                footer.props.linksLegal.push({
                    text: t('legal.terms.title'),
                    href: '/terms',
                    category: t('landingPage.footer.categories.legal.title'),
                    type: 'legal',
                    rel: 'nofollow',
                })
            } 
        }


    }

    const populatePageFooter = (page) => {
        notNullNotUndefined(page)

        let contentEffective = [...(page.content??[])];

        if (page.legalNotice || page.privacyPolicy) {
    
            const footerComponents = ['LandingFooterSaas', 'FooterSimple']
    
            let footer = contentEffective.find(c=>footerComponents.includes(c.type))
            if (!footer) {
                footer = createAutoFooter()
                contentEffective.push(footer)
            }
    
            addLegalLinksToFooter(footer, page)
        }

        return contentEffective

    }

    return { populatePageFooter }

}