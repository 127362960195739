import { UserAnonBorder } from 'modules/react-auth/component/border/UserAnonBorder';
import { useApp } from 'modules/yoio/useApp';
import { useAnalytics } from "modules/yoio/analytics/useAnalytics";

export const WebsitePageScaffold = ({children}) => {

    const { app } = useApp();
    const { consentRequired, saveConsent } = useAnalytics(app);

    const onSubmitConsent = (consent) => {
        saveConsent(consent);
    };

    return (
        <> 
        {
            app &&
            <>
                    {children}
                    {consentRequired && (
                    <UserAnonBorder>
                        {/* <ConsentManagement onSubmitConsent={onSubmitConsent} /> */}
                    </UserAnonBorder>
                    )}
            </>
        }
        </>
    );

}