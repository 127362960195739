import { useState } from 'react';
import { ListItem, List, Box, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/styles';
import dynamic from 'next/dynamic';

const LazyDrawer = dynamic(() =>
  import('@mui/material/Drawer')
)


/**
 * pass children or links
 * 
 * @param {*} itemsOnResponsiveOnly 
 *      Items taht are not shown in large screen mode but added in small screen mode
 *   
 * @returns 
 */
export default function ToolbarResponsive({itemsCenter, itemsRight, autoVisible, anchor, itemsOnResponsiveOnly, disableResponsiveMenu}) {

    const anchorEffective = anchor || 'right'; 

    const [state, setState] = useState({
        open: false,
    });

    const theme = useTheme()
    const drawerMode = useMediaQuery(theme.breakpoints.down('md'));

    const rootSx = []
    if (autoVisible==true) {
      rootSx.push((theme)=>({
        [theme.breakpoints.up('md')]: {
          display: 'none',
        },
      }))
    }

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
          return;
        }
    
        setState({ open });
      };

    const renderDrawerContent = (anchor) => {

      const allItems = []
      if (itemsOnResponsiveOnly) {
        allItems.push(...itemsOnResponsiveOnly)
      }
      if (itemsCenter) {
        allItems.push(...itemsCenter)
      }
      if (itemsRight) {
        allItems.push(...itemsRight)
      }

      const listCss = {
        width: 290,
        '& ul li+li': {
          //paddingTop: '16px',
        },
      }

      if (anchor === 'top' || anchor === 'bottom') {
        listCss.width = 'auto'
      }
      
        return (
          <div
            css={listCss}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <List>
              {allItems.map((i,idx) => (<ListItem key={idx}>{i}</ListItem>))}
            </List>
          </div>
        )
    }
    
    const hasMenuItems = itemsCenter?.length > 0 || itemsRight?.length > 0 || itemsOnResponsiveOnly?.length > 0

      return (
        <div css={rootSx}>
            {hasMenuItems && disableResponsiveMenu !== true &&
              <Box 
                color="text.primary" 
                sx={(theme)=>({        
                  [theme.breakpoints.up('md')]: {
                    display: 'none'
                }})
                }
                >
                  <IconButton edge="start" color="inherit" aria-label="menu" size="medium" onClick={toggleDrawer(true)}><MenuIcon /></IconButton>
              </Box>
            }
            {drawerMode && disableResponsiveMenu !== true &&
              <LazyDrawer anchor={anchorEffective} open={state['open']} onClose={toggleDrawer(false)}>
                  {(itemsCenter?.length > 0 || itemsRight?.length > 0 || itemsOnResponsiveOnly?.length > 0) && (
                    <>
                      {renderDrawerContent(anchorEffective)}
                    </>
                  )}
              </LazyDrawer>
            }
            <Box 
              sx={(theme)=>({
                  display: 'none',
                  [theme.breakpoints.up('md')]: {
                      display: 'flex'
                  },
              })}
            >
              {itemsCenter?.length > 0 && (
                <>
                  {itemsCenter.map((i,idx)=>{
                      return (
                          <Box key={idx} ml={7} display="flex" alignItems="center">{i}</Box>
                      )
                  })}
                  </>
              )}
                {itemsRight?.length > 0 && (
                  <Box ml={15} display="flex" alignItems="center">
                    {itemsRight.map((i,idx)=>{
                        return (
                            <Box 
                              key={idx} 
                              sx={(theme)=>({
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: theme.spacing(2),
                              })}
                            >{i}</Box>
                        )
                    })}
                  </Box>
                )}

            </Box>
        </div>
      );

}