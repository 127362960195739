import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { useScrollPosition } from '../utils/useScrollPosition';

export const ShowOnScroll = (props) => {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={true} direction="down" in={trigger}>
      {children}
    </Slide>
  );
}

ShowOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export const ShowOnScrollBelowPosition = ({children, threshold}) => {

  threshold = threshold || 120

  const { scrollPosition } = useScrollPosition(); 

  return (
    <Slide appear={true} direction="down" in={scrollPosition >= threshold}>
      {children}
    </Slide>
  );

}


export const HideOnScroll = (props) => {

  const { children, window, scrollClass, threshold } = props;
  
  const trigger = useScrollTrigger(
    { 
      target: window ? window() : undefined,
      threshold: threshold || 0
    });

  const { scrollPosition } = useScrollPosition(); 

  //add class when scrolled
  let contentEffective = null;
  if (scrollClass && scrollPosition > 120) {
    contentEffective = cloneElement(children, {
      className: children.className + ' ' + scrollClass,
    });
  } else {
    contentEffective = children
  }


  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {contentEffective}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export const HideOnScrollBelowPosition = ({children, threshold}) => {

  const { scrollPosition } = useScrollPosition(); 

  threshold = threshold || 120

  return (
    <Slide appear={false} direction="down" in={scrollPosition < threshold}>
      {children}
    </Slide>
  );

}

export const HideOnScrollBelowPositionNoEffect = ({children, threshold}) => {

  threshold = threshold || 120

  const { scrollPosition } = useScrollPosition(); 

  const show = scrollPosition < threshold

  return (<>{show && <>{children}</>}</>);

}