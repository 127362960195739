import { Theme } from "@emotion/react";
import { Popover, popoverClasses, PopoverOrigin, SxProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties, MutableRefObject, useCallback, useEffect } from "react";
import { ReactNode } from "react";
import { FunctionComponent, useRef, useState } from "react";

const useStyles = makeStyles((theme: Theme) => ({
    popover: {
      pointerEvents: "none"
    },
    paper: {
        pointerEvents: "auto",
        //@ts-ignore
        ...theme.customProps.popoverPaperStyle
    }
  }));

export interface InteractionChildrenForwardProps {
  ref?: MutableRefObject<any>
  onMouseEnter: ()=>void
  onMouseLeave: ()=>void
}

export interface InteractionPopoverProps {
  anchorOrigin?: PopoverOrigin
  transformOrigin?: PopoverOrigin
  content?: ReactNode  | ((wasOpen:boolean) => ReactNode)
  children?: (childProps: InteractionChildrenForwardProps, openedPopover: boolean)=>ReactNode
  paperStyle?: CSSProperties
  sx?: any
  disableEnforceFocus?: boolean
  openDelay?: number
  firstOpenDelay?: number
}

/**
 * 
 * Popover that also opens and stays open on hover.
 * 
 * @returns 
 */
export const InteractionPopover: FunctionComponent<InteractionPopoverProps> = ({children, content, anchorOrigin, transformOrigin, paperStyle, sx, disableEnforceFocus, openDelay = 0, firstOpenDelay = undefined, ...props}) => {

    const [openedPopover, setOpenedPopover] = useState<boolean>(false)
    const popoverAnchor = useRef(null)
    const [delayTimer, setDelayTimer] = useState(null)
   
    const wasOpened = useRef<boolean>(false)

    const classes = useStyles();

    useEffect(() => {
      return () => {
        if (delayTimer) {
          clearTimeout(delayTimer)
          setDelayTimer(null)
        }
      };
    }, [delayTimer])

    const popoverEnter = (ignoreDelay?:boolean) => {
      const openDelayEffective = ignoreDelay===true?0:(firstOpenDelay!==undefined&&firstOpenDelay!==null&&wasOpened.current===false?firstOpenDelay:openDelay)
      
      if (openDelayEffective > 0) {
        const timer = setTimeout(() => {
          wasOpened.current = true // before state change
          setOpenedPopover(()=>true)
          setDelayTimer(null)
        }, openDelayEffective);
        setDelayTimer(timer)
      } else {
        wasOpened.current = true // before state change
        setOpenedPopover(()=>true)
      }
    }
  
    const popoverLeave = () => {
      if (delayTimer) {
        clearTimeout(delayTimer)
        setDelayTimer(null)
      }
      setOpenedPopover(()=>false);
    }
  
    const childProps = {
        ref: popoverAnchor,
        'aria-owns': 'mouse-over-popover',
        'aria-haspopup': 'true',
        onMouseEnter: ()=>popoverEnter(),
        onMouseLeave: popoverLeave,
    };

    if (sx) {
      sx = [{
        pointerEvents: 'none',
        [`& .${popoverClasses.paper}`]: {
          pointerEvents: 'auto',
        }
      },sx]
    }

    return (
      <>
        {children(childProps, openedPopover)}
        <Popover
          id="mouse-over-popover"
          className={sx ? null : classes.popover}
          classes={sx ? null: {
            paper: classes.paper
          }}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          anchorOrigin={{
            vertical: anchorOrigin?.vertical || "center",
            horizontal: anchorOrigin?.horizontal || "left"
          }}
          transformOrigin={{
            vertical: transformOrigin?.vertical || "center",
            horizontal: transformOrigin?.horizontal || "right"
          }}
          PaperProps={{ onMouseEnter: ()=>popoverEnter(true), onMouseLeave: popoverLeave, style: paperStyle }}
          disableRestoreFocus={true}
          disableEnforceFocus={disableEnforceFocus}
          {...props}
          sx={sx}
        >
          {content instanceof Function ? content(wasOpened.current) : content}
        </Popover>
      </>
    );
  };
  